<template>
	<div id="app">
		<div class="viewer" v-if="$store.state.generator_mode">
			<Page0></Page0>
			<Page1></Page1>
			<Page3></Page3>
			<Page5></Page5>
		</div>
		<div id="popup_generation" v-if="$store.state.generator_mode">
			<i class="fad fa-spin fa-spinner"></i>
			Nous préparons votre contrat. <br />Cela peut durer quelques instants.<br />Merci pour votre patience.
			<div class="info_process" v-html="$store.state.message_generation"></div>
		</div>
		<router-view />
	</div>
</template>

<script>
import Page0 from "./components/pages/page0";
import Page1 from "./components/pages/page1";
import Page3 from "./components/pages/page3";
import Page5 from "./components/pages/page5";

export default {
	name: "app",
	components: { Page0, Page1, Page3, Page5 },
	data() {
		return {};
	},
	methods: {
		cancelgeneration() {
			this.$store.commit("setMessageGeneration", "Erreur lors de la création de la procédure de signature");
			setTimeout(() => {
				this.$store.commit("generatorMode", false);
			}, 2000);
		}
	}
};
</script>

<style lang="scss">
*,
html {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html {
	background: white;
	body {
		background: lighten(black, 94%);
		font-family: Helvetica, Arial, sans-serif;
		font-weight: normal;
		color: $black;
		#app {
			width: 100vw;
			.page {
				display: flex;
				flex-wrap: wrap;
			}
			.viewer {
				background: lighten(black, 97%);
				width: 1512px;
				height: 2088px;
				padding: 0px !important;
				.generation {
					display: flex;
					text-align: center;
					flex-direction: column;
					font-size: 13px;
					margin-top: 100px;
					i,
					svg {
						font-size: 30px;
						margin-bottom: 20px;
						color: $rouge;
					}
				}
			}
			#popup_generation {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(white, 9);
				background-blend-mode: blur(10px);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 16px;
				line-height: 22px;
				z-index: 9999;
				.close_popup_generation {
					position: absolute;
					top: 30px;
					right: 30px;
					text-decoration: underline;
					font-size: 13px;
					cursor: pointer;
				}
				i,
				svg {
					color: $rouge;
					font-size: 30px;
					margin-bottom: 20px;
				}
				.info_process {
					font-size: 12px;
					color: lighten($black, 30%);
					margin-top: 30px;
				}
			}
		}
		&.printmode {
			padding: 0;
			margin: 0;
			#app {
				padding: 0;
				margin: 0;
			}
		}
	}
}
</style>
