<template>
	<div class="box">
		<div class="part zonetexte">
			<div v-if="client.fields['Classe de partenaire'] === 'Partenaire (MAP)'" v-html="charte_partenariat_partenaire"></div>
			<div v-if="client.fields['Classe de partenaire'] === 'Proactif Gold (MIP)'" v-html="charte_partenariat_proactif"></div>
			<div v-if="client.fields['Classe de partenaire'] === 'Proactif Platinium (MI+)'" v-html="charte_partenariat_proactif_plus"></div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Chartes",
	components: {},
	data() {
		return {};
	},
	computed: {
		charte_partenariat_proactif_plus() {
			return this.$store.state.chartes[0].fields.charte_partenariat_proactif_plus;
		},
		charte_partenariat_proactif() {
			return this.$store.state.chartes[0].fields.charte_partenariat_proactif;
		},
		charte_partenariat_partenaire() {
			return this.$store.state.chartes[0].fields.charte_partenariat_partenaire;
		},
		client() {
			return this.$store.state.current_client;
		}
	},
	methods: {}
};
</script>

<style lang="scss"></style>
