<template>
	<div class="box" id="documents">
		<div class="part zonetexte">
			<h2>Remarque</h2>
			<p>
				Les documents PDF s'ouvriront dans un nouvel onglet. Il vous suffira de changer (ou fermer) l'onglet pour retourner sur la saisie de votre PAC.
			</p>
		</div>
		<div class="part zonetexte">
			<h2>Consultation des documents</h2>

			<div class="documents" v-if="documents">
				<a :href="doc.url" class="document" v-for="(doc, index) in documents" :key="index" target="_blank"> <i class="fal fa-file-pdf"></i>{{ doc.filename }} </a>
				<!-- <a v-if="client.fields['Classe de partenaire'].includes('Partenaire')" class="document" href="https://www.challengeviessmann.fr/" target="_blank"
					><i class="fal fa-link"></i>Challenge Partenaires Viessmann</a
				> -->
			</div>
			<p v-else>Aucun document disponible pour cette classification.</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "Documentation",
	components: {},
	data() {
		return {};
	},
	computed: {
		documents() {
			let document = [];
			if (this.$store.state.current_client.fields["Classe de partenaire"] === "Basic (PAR)") {
				document = this.$store.state.documents[0].fields.documents_basics;
			} else if (this.$store.state.current_client.fields["Classe de partenaire"] === "Partenaire (MAP)") {
				document = this.$store.state.documents[0].fields.documents_partenaires;
			} else if (this.$store.state.current_client.fields["Classe de partenaire"] === "Prospect (EK)") {
				document = this.$store.state.documents[0].fields.documents_prospects;
			} else if (this.$store.state.current_client.fields["Classe de partenaire"] === "Proactif Gold (MIP)") {
				document = this.$store.state.documents[0].fields.documents_proactifs;
			} else if (this.$store.state.current_client.fields["Classe de partenaire"] === "Proactif Platinium (MI+)") {
				document = this.$store.state.documents[0].fields.documents_proactif_plus;
			}
			return document;
		},
		client() {
			return this.$store.state.current_client;
		}
	},
	methods: {}
};
</script>

<style lang="scss">
#documents {
	.documents {
		display: flex;
		flex-direction: column;
		.document {
			display: flex;
			align-items: flex-start;
			font-size: 12px;
			font-weight: 500;
			color: $black;
			padding: 6px 0;
			i,
			svg {
				color: $bleu;
				font-size: 20px;
				margin-right: 10px;
			}
		}
	}
}
</style>
