<template>
	<div class="box">
		<div class="part">
			<h2>Plan d'action commercial</h2>
			<h3>Classification du client</h3>
			<!-- <div class="data">
				<label>Classification (matrice)</label>
				<select v-model="classification" @change="handleChange($event, 'Classification (Matrice)')">
					<option value="Basic">Basic</option>
					<option value="Silver">Silver</option>
					<option value="Gold">Gold</option>
					<option value="Black">Black</option>
				</select>
			</div> -->
			<div class="data">
				<label>Branche</label>
				{{ client.fields["Branche"] }}
			</div>
			<div class="data">
				<label>Classe de partenaire (si PAC signé)</label>
				<select v-model="classe" @change="handleChange($event, 'Classe de partenaire')">
					<option value="Basic (PAR)">Basic (PAR)</option>
					<option value="Partenaire (MAP)">Partenaire (MAP)</option>
					<option value="Prospect (EK)">Prospect (EK)</option>
					<option value="Proactif Gold (MIP)">Proactif Gold (MIP)</option>
					<option value="Proactif Platinium (MI+)">Proactif Platinium (MI+)</option>
				</select>
			</div>
			<div class="data">
				<label>Visites réalisées l'année passée</label>
				{{ client.fields["Visites réalisées"] }}
			</div>
			<div class="data">
				<label>Objectif de visites</label>
				<input type="number" v-model="objectifs_visites" @change="handleChangeNumber($event, 'Objectif visites')" />
			</div>
			<div class="data">
				<label>Potentiel du client</label>
				<input type="number" v-model="potentiel" @change="handleChangeNumber($event, 'Potentiel du client')" />
			</div>
			<!-- <div class="data">
				<label>Potentiel en PAC</label>
				{{ client.fields["Potentiel en PAC"] }}
				<input type="number" v-model="client.fields['Potentiel en PAC']" @change="handleChangeNumber($event, 'Potentiel en PAC')" />
			</div> -->
			<!-- <div class="data">
				<label>Potentiel en CLIM</label>
				{{ client.fields["Potentiel en CLIM"] }}
				<input type="number" v-model="client.fields['Potentiel en CLIM']" @change="handleChangeNumber($event, 'Potentiel en CLIM')" />
			</div> -->
			<div class="data">
				<label>Installateur du neuf</label>
				<van-switch active-color="#558b2f" size="20" v-model="client.fields['Installateur du neuf']" @change="handleChange($event, 'Installateur du neuf')" />
			</div>
			<div class="data">
				<label>154 Non primable</label>
				<van-switch active-color="#558b2f" size="20" v-model="client.fields['154 Non primable']" @change="handleChange($event, '154 Non primable')" />
			</div>

			<!-- Ne faire apparaitre que pour les basic !-->
			<!-- <div class="data" v-if="client.fields['Classification (Matrice)'] == 'Basic'">
				<label>Cibler en prospect</label>
				<input type="text" :value="cibler_prospect ? 'Oui' : 'Non'" readonly />
			</div> -->
			<!-- Ne faire apparaitre que pour les silver !-->

			<!-- <div
				class="data"
				v-if="
					(wild_card && wild_card > 0 && client.fields['Classification (Matrice)'] == 'Silver') ||
						(use_wild_card && client.fields['Classification (Matrice)'] == 'Silver')
				"
			>
				<label>Utiliser une WildCard Proactif ({{ wild_card }} restante-s)</label>
				<van-switch active-color="#558b2f" size="20" v-model="use_wild_card" @change="update_wild_card()" />
			</div> -->

			<h3>Crédit Management</h3>
			<div class="data">
				<label>Plafond de crédit actuel</label>
				{{ client.fields["Plafond de crédit actuel"] | currency }}
			</div>
			<!-- <div class="data">
				<label>Plafond de crédit souhaité</label>
				{{  }}
				<input type="number" v-model="plafond_souhaite" @change="handleChangeNumber($event, 'Plafond de crédit souhaité')" />
			</div> -->
		</div>
	</div>
</template>
<script>
export default {
	name: "PAC",
	components: {},
	computed: {
		client() {
			return this.$store.state.current_client;
		},
		// objectifs_visites() {
		// 	let temp = 0;
		// 	if (this.client.fields["Classe de partenaire"] == "Basic (PAR)") {
		// 		temp = 0;
		// 	} else if (this.client.fields["Classe de partenaire"] === "Partenaire (MAP)") {
		// 		temp = 8;
		// 	} else if (this.client.fields["Classe de partenaire"] == "Prospect (EK)") {
		// 		temp = 24;
		// 	} else if (this.client.fields["Classe de partenaire"] == "Proactif Gold (MIP)") {
		// 		temp = 12;
		// 	} else if (this.client.fields["Classe de partenaire"] == "Proactif Platinium (MI+)") {
		// 		temp = 16;
		// 	}
		// 	this.$store.commit("updateStoreField", {
		// 		field: "Objectif visites",
		// 		value: temp
		// 	});
		// 	return temp;
		// }
	},
	data() {
		return {
			classe: this.$store.state.current_client.fields["Classe de partenaire"],
			cibler_prospect: this.$store.state.current_client.fields["Cibler en prospect"],
			plafond_souhaite: this.$store.state.current_client.fields["Plafond de crédit souhaité"],
			wild_card: this.$store.state.user.fields.wild_card,
			use_wild_card: this.$store.state.current_client.fields["Wild Card Proactif"],
			classification: this.$store.state.current_client.fields["Classification (Matrice)"] || "Basic",
			objectifs_visites: this.$store.state.current_client.fields["Objectif visites"] || 0,
			potentiel: this.$store.state.current_client.fields["Potentiel du client"] || 0,
		};
	},
	methods: {
		update_wild_card() {
			if (this.use_wild_card) {
				if (this.client.fields["Classification (Matrice)"] == "Silver") {
					this.classe = "Proactif Gold (MIP)";
					this.wild_card--;
				}
			} else {
				if (this.client.fields["Classification (Matrice)"] == "Silver") {
					this.classe = "Partenaire (MAP)";
					this.wild_card++;
				}
			}
			this.$store.commit("updateStoreField", {
				field: "Classe de partenaire",
				value: this.classe
			});
			this.$store.commit("updateStoreField", {
				field: "Objectif visites",
				value: this.objectifs_visites
			});
			this.$store.commit("updateStoreField", {
				field: "Wild Card Proactif",
				value: this.use_wild_card
			});
			this.$store.commit("updateUser", {
				field: "wild_card",
				value: this.wild_card
			});
		},
		is_prospect() {
			if (this.cibler_prospect && this.client.fields["Classification (Matrice)"] == "Basic") {
				this.classe = "Prospect (EK)";
			} else {
				this.classe = "Basic (PAR)";
			}
			this.$store.commit("updateStoreField", {
				field: "Cibler en prospect",
				value: this.cibler_prospect
			});
			this.$store.commit("updateStoreField", {
				field: "Classe de partenaire",
				value: this.classe
			});
			this.$store.commit("updateStoreField", {
				field: "Objectif visites",
				value: this.objectifs_visites
			});
		},
		handleChange(e, field) {
			if (e.target) {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e.target.value
				});
			} else {
				this.$store.commit("updateStoreField", {
					field: field,
					value: e
				});
			}
		},
		handleChangeNumber(e, field) {
			if (e.target) {
				this.$store.commit("updateStoreField", {
					field: field,
					value: parseInt(e.target.value)
				});
			} else {
				this.$store.commit("updateStoreField", {
					field: field,
					value: parseInt(e)
				});
			}
		}
	}
};
</script>

<style lang="scss"></style>
